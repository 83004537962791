export default class CardService {
  // _apiBase = 'http://127.0.0.1:3333/api/v1';
  // _apiBase = 'https://api.ihope2cu.com/api/v1';
  _apiBase = 'https://api-dev.ihopetoseeyou.com/api/v1';
  // _apiBase = 'https://api.ihopetoseeyou.com/api/v1';

  async userAuth(url, user) {
    const res = await fetch(`${this._apiBase}/auth${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ ...user }),
    });

    if (!res.ok) {
      // console.log('res', res)
      const status = res.status;
      if (status === 422) {
        throw await res.json();
      }
      //  else if (status === 401) {
      //   // throw res;
      // }
      throw res;
      // throw new Error(`Could not fetch ${url}, received ${res.status}`);
    }
    return await res.json();
  }

  async getUser(token) {
    const res = await fetch(`${this._apiBase}/profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`Could not fetch user, received ${res.status}`);
    }
    return await res.json();
  }

  async userRegister(user) {
    return await this.userAuth('/register', user);
  }

  async userLogin(user) {
    return await this.userAuth('/access', user);
  }

  async userLogout(user) {
    return await this.userAuth('/revoke', user);
  }

  async userUpdatePass(newpass, token) {
    const res = await fetch(`${this._apiBase}/profile/update-password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ password: newpass }),
    });

    if (!res.ok) {
      throw new Error(`Could not fetch user, received ${res.status}`);
    }
    return await res.json();
  }

  async userForgotPass(email) {
    const res = await fetch(`${this._apiBase}/auth/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (!res.ok) {
      throw new Error(`Could not fetch user, received ${res.status}`);
    }
    return await res.json();
  }

  async contactMessage(obj) {
    const res = await fetch(`${this._apiBase}/contact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ ...obj }),
    });

    // if (!res.ok) {
    //   throw new Error(`Could not fetch user, received ${res.status}`);
    // }
    if (!res.ok) {
      // console.log('res', res)
      const status = res.status;
      if (status === 422) {
        throw await res.json();
      }
      //  else if (status === 401) {
      //   // throw res;
      // }
      throw res;
      // throw new Error(`Could not fetch ${url}, received ${res.status}`);
    }
    return await res.json();
  }

  async getResource(url) {
    const res = await fetch(`${this._apiBase}/data${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    if (!res.ok) {
      throw new Error(`Could not fetch ${url}, received ${res.status}`);
    }
    return await res.json();
  }

  async getCategoryList() {
    return await this.getResource('/categories');
  }

  async getCategoryListHome() {
    return await this.getResource('/categories-home');
  }

  async getCategoryItem(slug) {
    return await this.getResource(`/categories/${slug}`);
  }

  async getTagList() {
    return await this.getResource('/tags');
  }

  async getCard(id) {
    return await this.getResource(`/cards/${id}`);
  }

  async getCardsByCategory(category) {
    return await this.getResource(`/categories/cards/${category}`);
  }

  async getCardsByCategoryId(id) {
    return await this.getResource(`/categories/cards/category/${id}`);
  }

  async getCardsByTag(category, tag) {
    return await this.getResource(`/tags/cards/${category}/${tag}`);
  }

  async getBanners() {
    return await this.getResource('/banners');
  }

  async setOrder(order) {
    const res = await fetch(`${this._apiBase}/orders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ ...order }),
    });

    if (!res.ok) {
      // console.log('res', res)
      const status = res.status;
      if (status === 422) {
        throw await res.json();
      }
      throw res;
      // throw new Error(`Could not fetch ${url}, received ${res.status}`);
    }
    return await res.json();
  }

  async setDraft(card) {
    const res = await fetch(`${this._apiBase}/orders/save-draft`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ ...card }),
    });

    if (!res.ok) {
      // console.log('res', res)
      const status = res.status;
      if (status === 422) {
        throw await res.json();
      }
      throw res;
      // throw new Error(`Could not fetch ${url}, received ${res.status}`);
    }
    return await res.json();
  }

  async getDraft(key) {
    const res = await fetch(`${this._apiBase}/orders/draft/${key}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    if (!res.ok) {
      // console.log('res', res)
      const status = res.status;
      if (status === 422) {
        throw await res.json();
      }
      throw res;
      // throw new Error(`Could not fetch ${url}, received ${res.status}`);
    }
    return await res.json();
  }

  async getMenu() {
    return await this.getResource('/menu');
  }

  async getMainMenu() {
    return await this.getResource('/main-menu');
  }

  async getChatySettings() {
    const res = await fetch(`${this._apiBase}/data/chaty`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    if (!res.ok) {
      throw new Error(`Could not fetch Chaty settings, received ${res.status}`);
    }
    return await res.json();
  }

  async getBestSellers(categoryId) {
    const res = await fetch(`${this._apiBase}/data/best-sellers/${categoryId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    if (!res.ok) {
      throw new Error(`Could not fetch Best sellers, received ${res.status}`);
    }
    return await res.json();
  }

  // helpers
  formsErrorHandler(err) {
    let newFormErrors = {};
    let fieldNames = [];
    err.errors.map((item) => {
      if (!fieldNames.includes(item.field)) {
        newFormErrors[item.field] = item.message;
        if (item.rule === 'minLength' && item.args && item.args.minLength) {
          newFormErrors[item.field] = `min length: ${item.args.minLength}`;
        }
        fieldNames.push(item.field);
      }
      return item;
    });
    // console.log('newFormErrors', newFormErrors);
    return newFormErrors;
  }

  async getFooterMenu() {
    return await this.getResource('/footer-links');
  }

  async getVideo() {
    return await this.getResource('/how-it-works-media');
  }

  async getNewCards() {
    return await this.getResource('/new-design-cards');
  }

  async getFAQ() {
    return await this.getResource('/frequently-asked-questions');
  }

  async getIcon(url) {
    const res = await fetch(url);
    if (res) {
      return await res.text();
    }
  }

  async getVideoAvailability() {
    return await this.getResource('/video-availability');
  }

  async getSectionAvailability() {
    return await this.getResource('/section-availability');
  }

  async getVideoStatusButton() {
    return await this.getResource('/video-status-button');
  }

  async getSearchResults(query) {
    return await this.getResource(`/search?query=${query}`);
  }
}
